/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import styled from "styled-components"
import { Formik } from "formik"
import { withFirebase } from "../../../utils/Firebase"
import Switch from "../../atoms/Switch"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"

export const rolesData = {
  roles: [
    {
      name: "Administrador Local*",
      code: "LOCAL_ADMIN",
    },
    {
      name: "Administrador Corporativo*",
      code: "CORP_ADMIN",
    },
    {
      name: "Asesor de Mercadeo*",
      code: "MARKETING_ADVISOR",
    },
    {
      name: "Representante Médico",
      code: "MEDICAL_REP",
    },
    {
      name: "Representante de Asociados de Negocios",
      code: "BUSINESS_ASSOCIATES_REP",
    },
    {
      name: "Promotor",
      code: "PROMOTOR",
    },
  ],
}

const RolesDrawer = ({ firebase, userEmail, open, closeDrawer }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState([])
  const [currentUser, setCurrentUser] = useState()

  const closeRolesDrawer = () => {
    setSelectedRoles([])
    closeDrawer()
  }

  const onChangeRoles = (e, role) => {
    const roles = [...selectedRoles]
    if (selectedRoles.includes(role.code)) {
      roles.splice(selectedRoles.indexOf(role.code), 1)
    } else {
      roles.push(role.code)
    }
    setSelectedRoles(roles)
  }

  useEffect(() => {
    if (userEmail) {
      firebase
        .user(userEmail)
        .get()
        .then((snapshot) => {
          setCurrentUser(snapshot.data())
        })
    }
    return () => {}
  }, [userEmail, firebase])

  useEffect(() => {
    const rolesArray = []
    if (currentUser) {
      const createRolesArray = async (userRoles) => {
        if (Object.entries(userRoles).length > 0) {
          Object.entries(userRoles).forEach(([key, value]) => {
            if (value) {
              rolesArray.push(key)
            }
          })
          const currentRoles = rolesArray.filter((role) => {
            return rolesData?.roles?.find((roleData) => roleData.code === role)
          })
          setSelectedRoles(currentRoles)
        }
      }
      createRolesArray(currentUser.roles ? currentUser.roles : {})
    }
  }, [currentUser])

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeRolesDrawer}
      title={t("assign_role")}
      internal
    >
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            roles: selectedRoles,
          }}
          onSubmit={async (values) => {
            setIsCreating(true)
            try {
              const newUserRoles = currentUser?.roles ? currentUser?.roles : {}
              const currentRoles = currentUser?.roles ?? {}
              rolesData?.roles?.forEach((role) => {
                const key = role.code
                if (
                  values.roles.includes(role.code) ||
                  (!currentRoles[key] && values.roles.includes(role.code))
                ) {
                  newUserRoles[key] = true
                } else if (
                  currentRoles[key] &&
                  !values.roles.includes(role.code)
                ) {
                  newUserRoles[key] = false
                }
              })
              await firebase.saveUserData({
                email: currentUser.email,
                data: {
                  roles: newUserRoles,
                },
              })
              addToast("Se han asignado los roles exitosamente.", {
                appearance: "success",
                placement: "bottom-center",
              })
            } catch (error) {
              addToast("Ha ocurrido un error al asignar los roles.", {
                appearance: "error",
                placement: "bottom-center",
              })
            }
            setSelectedRoles([])
            setIsCreating(false)
            closeDrawer()
          }}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              {rolesData?.roles?.map((role) => (
                <CheckboxContainer key={role.code}>
                  <Switch
                    style={{
                      display: "inline-block",
                      height: "26px",
                      alignItems: "center",
                    }}
                    name="roles"
                    id={role.code}
                    checked={selectedRoles.includes(role.code)}
                    value={props.values.roles[role.code]}
                    onChange={(e) => {
                      props.setFieldValue("roles", role.code)
                      onChangeRoles(e, role)
                    }}
                  />
                  <CheckboxText>{role.name}</CheckboxText>
                </CheckboxContainer>
              ))}
              <SubmitButton
                iconName="save"
                isLoading={isCreating}
                type="submit"
              >
                {t("save_button")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

RolesDrawer.defaultProps = {
  open: false,
}

RolesDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
}
export default withFirebase(RolesDrawer)

const CheckboxText = styled.p`
  display: inline;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`
