import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import media from "../../../theme/media"
import { Container } from "../ActionButton"

export const NarrowActionButton = styled(Container)`
  width: auto;
`

export const ActionButtonLink = styled(Container).attrs({
  as: "a",
})`
  ${({ disabled }) =>
    disabled
      ? `
      opacity: 0.3;
      cursor: not-allowed !important;
      `
      : ""}
`

export const ActionButtonExternalLink = styled.a(
  ({ theme }) => css`
    cursor: pointer;
    height: ${rem(40)};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border-radius: 3px;
    font-size: ${rem("14px")};
    letter-spacing: 1px;
    transition: background-color 0.3s ease-in-out;
    background-color: ${theme.color.bg.light};
    background: ${theme.color.bg.grayGradient};
    border: 1px solid ${theme.color.border.default};
    color: ${theme.color.black["500"]};
    height: ${rem(26)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    width: 100%;
    line-height: ${rem(12)};
    font-size: ${rem(12)};
    font-weight: 600;
    margin-right: ${rem(24)};
    &:last-child {
      margin-right: 0;
    }
  `
)

export const Table = styled.div`
  padding: 0;
  width: calc(100vw - 150px);
`

export const MobileTableHeader = styled.div(
  ({ theme }) => css`
    padding: ${rem(8)} ${rem(16)};
    width: 100%;
    align-content: center;
    border: ${rem(1)} solid ${theme.color.border.default};
    background-color: ${theme.color.bg.secondary};
    user-select: none;
    display: flex;
    ${media.large`
    display: none;
  `}
  `
)

const GridStyles = css`
  display: grid;
  margin-bottom: 0;
  grid-template-areas: unset;
  grid-template-columns: ${({ children, columns }) =>
    console.log(children) || columns
      ? columns.map(({ size, name }) => `${size} [${name}]`).join(" ")
      : `repeat(${React.Children.count(children.filter((c) => c))}, ${
          100 / React.Children.count(children.filter((c) => c))
        }%)`};
`

export const TableHeader = styled.div(
  ({ theme }) => css`
    padding: ${rem(2)} ${rem(6)};
    width: 100%;
    align-content: center;
    border: ${rem(1)} solid ${theme.color.border.default};
    border-left: 0px;
    border-right: 0px;
    user-select: none;
    display: none;
    font-size: ${rem(14)};
    ${media.large`${GridStyles}`};
  `
)

export const TableHeaderItem = styled.span`
  color: inherit;
  font-weight: 600;
  border: none;
  padding: 0;
  background-color: transparent;
  text-transform: inherit;
  align-items: center;
  display: flex;

  ${media.large`
    padding: ${rem(12)}  ${rem(8)};
  `}
`

export const TableRowItem = styled.div`
  display: flex;
  font-size: ${rem(14)};
  align-items: center;
  text-align: left;
  text-overflow: ellipsis;
  grid-area: ${({ name }) => name};

  ${media.large`
    grid-area: auto;
    padding: ${rem(12)} ${rem(8)};
  `};
  > a {
    text-decoration: none;
    cursor: pointer;
    width: auto;
  }
  ${ActionButtonLink} {
    text-decoration: none;
  }
`
export const TableRowItemBrokenWords = styled(TableRowItem)`
  word-break: break-word;
`

export const TableRowItemCenterAlignedContent = styled(TableRowItem)`
  justify-content: center;
`

export const TableRow = styled.div(
  ({ areas, isVerticalOnMobile, theme }) => css`
    display: grid;
    grid-template-columns: ${isVerticalOnMobile
      ? "1fr"
      : "repeat(2, calc(50%))"};
    padding: ${rem(16)};
    width: 100%;
    background-color: ${theme.color.bg.light};
    border-bottom: ${rem(1)} solid ${theme.color.border.default};
    grid-template-areas: ${areas
      ? areas.map((area) => `"${area}"`).join("\n")
      : "unset"};

    ${media.large`
      ${GridStyles};
      padding: ${rem(6)};
  `};
  `
)

export const TableColumn = styled.div`
  display: flex;
  flex-direction: column;

  div {
    padding: ${rem(8)};
  }
`

export const LeftTableRowItemMobile = styled(TableRowItem)`
  justify-self: end;

  ${media.large`
    justify-self: start;
  `}
`
export const CenterTableRowItemMobile = styled(TableRowItem)`
  justify-self: start;

  ${media.large`
    text-align: center;
    justify-content: center;
  `}
`

export const CenteredTableRowItem = styled(TableRowItem)`
  text-align: center;
  justify-content: center;
`
export const TableFooter = styled.div(
  ({ theme }) => css`
    width: calc(100vw - 170px);
    padding: ${rem(16)};
    margin-bottom: ${rem(48)};
    min-height: ${rem(64)};
    border: ${rem(1)} solid ${theme.color.border.default};
    background-color: ${theme.color.background.secondary};
    font-size: ${rem(14)};
    font-weight: 600;
    div:first-child {
      color: ${theme.color.darkPurple};
    }
    ${media.large`
    border-top: none;
  `};
  `
)

export const EmptyOrLoadingContainer = styled.div(
  ({ theme }) => css`
    grid-area: 1 / 1 / 1 / 100;
    height: ${rem(96)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: ${rem(16)};
    color: ${theme.color.lightPurple};
    background-color: ${theme.color.bg.light};
    font-style: italic;
    border-bottom: ${rem(1)} solid ${theme.color.border.default};
    padding: 0 ${rem(12)};
    width: calc(100vw - 170px);
  `
)

export const TableLink = styled.a(
  ({ theme }) => css`
    text-decoration: underline;
    cursor: pointer;
    color: ${theme.palette.blue};
    &:visited {
      color: ${theme.palette.purple};
    }
  `
)
