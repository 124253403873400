/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import get from "lodash.get"
import has from "lodash.has"
import unset from "lodash.unset"
import startCase from "lodash.startcase"
import replace from "lodash.replace"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { usePaymentInputs } from "react-payment-inputs"
import Cards from "react-credit-cards"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import CustomModal from "../../atoms/CustomModal"
import {
  ModalWrapper,
  ModalOptions,
  ButtonsContainer,
  Title,
  ModalContainer,
} from "../../atoms/ModalElements"
import CreditCard from "../../atoms/CreditCard"
import {
  tokenDeleteUrl,
  customerCreateUrl,
  tokenCreateUrl,
} from "../../../utils/App"
import { withFirebase } from "../../../utils/Firebase"
import creditCard from "../../../static/images/credit-card.png"
import {
  Container,
  InfoWrapper,
  StyledForm,
  SubmitButton,
  PaymentsHeader,
  AddButton,
  DoubleInputWrapper,
  InputWrapper,
  CardInput,
  ErrorMessage,
  BillingSection,
  BillingContainer,
  BillingAddres,
  BillingTitle,
  BillingText,
} from "./styles"
import "react-credit-cards/es/styles-compiled.css"

const OrgPayments = ({ org, orgId, addresses, firebase }) => {
  const { addToast } = useToasts()
  const { getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs()
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [address, setAddress] = useState(
    addresses && addresses.length > 0 ? addresses[0].data() : null
  )
  const [visibleAddAddress, setVisibleAddAddress] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [tokens, setTokens] = useState({})
  const [payload, setPayload] = useState(null)
  const [stripeUserId, setStripeUserId] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    const createStripeUser = async () => {
      try {
        const token = await firebase.getIdToken()
        const customer = await fetch(customerCreateUrl, {
          method: "POST",
          body: JSON.stringify({
            client: {
              uid: org.uid,
              email: org.email,
              name: startCase(org.name),
              country: org.country,
            },
            isOrg: true,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        const parsedCustomer = await customer.json()
        if (parsedCustomer && parsedCustomer.stripeUserId == null) {
          setPayload(null)
          setLoading(false)
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
          // Alert.alert("Error", I18n.t("something_went_wrong"))
        } else {
          setStripeUserId(customer.data.stripeUserId)
        }
      } catch (error) {
        setPayload(null)
        // setSaving(false)
      }
    }
    if (!org.stripeUserId) {
      createStripeUser()
    } else {
      setStripeUserId(org.stripeUserId)
    }
  }, [firebase, orgId])

  useEffect(() => {
    let unsubscribe
    if (firebase && org.uid) {
      const docs = tokens
      unsubscribe = firebase.subscribeToTokens({
        orgId: org.uid,
        onSnapshot: (s) => {
          s.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (!has(docs, change.doc.id)) {
                docs[change.doc.id] = change.doc.data()
              }
            }
            if (change.type === "modified") {
              docs[change.doc.id] = change.doc.data()
            }
            if (change.type === "removed") {
              unset(docs, change.doc.id)
            }
          })
          setTokens({ ...docs })
          setLoading(false)
        },
      })
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [firebase, orgId])

  useEffect(() => {
    if (deleting && payload != null && stripeUserId) {
      const deleteCardToken = async () => {
        try {
          const token = await firebase.getIdToken()
          const card = await fetch(tokenDeleteUrl, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          const parsedCard = await card.json()
          setDeleting(false)
          if (parsedCard && parsedCard.status === "deleted") {
            addToast("Eliminada correctamente!", {
              appearance: "success",
              placement: "bottom-center",
            })
          } else {
            addToast(t("unexpected_error"), {
              appearance: "error",
            })
          }
        } catch (error) {
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
        }
      }

      deleteCardToken()
    }
  }, [deleting, payload])

  return (
    <Container>
      <PaymentsHeader>
        <div>
          <img alt="card icon" src={creditCard} />
        </div>
        <div>
          <Title m="8px">{t("create_credit_card")}</Title>
          <p style={{ marginLeft: 8, marginTop: 0 }}>
            {t("add_payment_methods")}
          </p>
        </div>
        <AddButton onClick={() => setIsModalOpen(!isModalOpen)}>
          <span>+</span>
        </AddButton>
      </PaymentsHeader>
      <InfoWrapper isDeleting={deleting}>
        {tokens &&
          Object.keys(tokens).map((tt) => (
            <CreditCard
              key={tt}
              card={tokens[tt]}
              onCardClick={() => {
                setDeleting(true)
                setPayload({
                  card: {
                    id: tokens[tt].token,
                    key: tt,
                  },
                  client: {
                    uid: org.uid,
                    email: org.email,
                    userId: org.stripeUserId,
                  },
                  isOrg: true,
                })
              }}
            />
          ))}
      </InfoWrapper>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions w="900px" h={visibleAddAddress ? "100%" : "500px"}>
              <section>
                <Title m="8px">{t("add_card")}</Title>
                <ModalContainer>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      cvc: undefined,
                      expiryDate: undefined,
                      focus: undefined,
                      name: undefined,
                      cardNumber: undefined,
                      address_line1: address ? address.address_line1 : "",
                      address_line2: address ? address.address_line2 : "",
                      address_city: address ? address.address_city : "",
                      address_state: address ? address.address_state : "",
                      address_zip: address ? address.address_zip : "",
                      address_country: address ? address.address_country : "",
                    }}
                    onSubmit={async (values) => {
                      setLoading(true)
                      try {
                        if (!address) {
                          setAddress({
                            address_line1: values.address_line1,
                            address_line2: values.address_line2,
                            address_city: values.address_city,
                            address_state: values.address_state,
                            address_zip: values.address_zip,
                            address_country: values.address_country,
                          })
                          await firebase.addOrgBillingAddress({
                            orgId: org.uid,
                            billingAddress: {
                              address_line1: values.address_line1,
                              address_line2: values.address_line2,
                              address_city: values.address_city,
                              address_state: values.address_state,
                              address_zip: values.address_zip,
                              address_country: values.address_country,
                            },
                          })
                        }
                        const expiry = values.expiryDate.split("/")
                        const createPayload = {
                          client: {
                            uid: org.uid,
                            email: org.email,
                            userId: org.stripeUserId,
                          },
                          card: {
                            number: replace(values.cardNumber, / /g, ""),
                            cvc: values.cvc,
                            name: values.name,
                            exp_month: expiry[0].trim(),
                            exp_year: `20${expiry[1].trim()}`,
                            address_line1: values.address_line1,
                            address_line2: values.address_line2,
                            address_city: values.address_city,
                            address_state: values.address_state,
                            address_zip: values.address_zip,
                            address_country: values.address_country,
                          },
                          isOrg: true,
                        }
                        const token = await firebase.getIdToken()
                        const card = await fetch(tokenCreateUrl, {
                          method: "POST",
                          body: JSON.stringify(createPayload),
                          headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        })
                        setLoading(false)
                        const parsedCard = await card.json()
                        if (
                          card.ok ||
                          (parsedCard && parsedCard.status === "created")
                        ) {
                          addToast(t("saved_successfully"), {
                            appearance: "success",
                            placement: "bottom-center",
                          })
                          setIsModalOpen(false)
                        } else {
                          addToast(t("unexpected_error"), {
                            appearance: "error",
                          })
                        }
                      } catch (error) {
                        setLoading(false)
                        addToast(t("unexpected_error"), {
                          appearance: "error",
                        })
                      }
                    }}
                    validationSchema={Yup.object({
                      cvc: Yup.string()
                        .min(3)
                        .max(4)
                        .required(t("error_all_fields_required")),
                      expiryDate: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      focus: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      name: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      cardNumber: Yup.string()
                        .max(19)
                        .min(15)
                        .required(t("error_all_fields_required")),
                      address_country: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      address_line1: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                    })}
                  >
                    {(props) => (
                      <StyledForm onSubmit={props.handleSubmit}>
                        <section>
                          <div>
                            <Cards
                              cvc={props.values.cvc || ""}
                              expiry={
                                props.values.expiryDate
                                  ? props.values.expiryDate.replace("/", "")
                                  : ""
                              }
                              focused={props.values.focus || ""}
                              name={props.values.name || ""}
                              number={props.values.cardNumber || ""}
                            />
                          </div>
                          <div>
                            <Field name="name">
                              {({ field, form, meta }) => (
                                <InputWrapper>
                                  <CardInput
                                    type="text"
                                    placeholder="Nombre Titular"
                                    onFocus={() =>
                                      form.setFieldValue("focus", "name")
                                    }
                                    {...field}
                                  />
                                  {meta.touched && meta.error && (
                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                  )}
                                </InputWrapper>
                              )}
                            </Field>
                            <Field name="cardNumber">
                              {({ field, meta }) => (
                                <InputWrapper>
                                  <CardInput
                                    {...getCardNumberProps({
                                      placeholder: t("card_number_label"),
                                      onBlur: field.onBlur,
                                      onChange: field.onChange,
                                      onFocus: () =>
                                        props.setFieldValue("focus", "number"),
                                    })}
                                  />
                                  {meta.touched && meta.error && (
                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                  )}
                                </InputWrapper>
                              )}
                            </Field>
                            <DoubleInputWrapper>
                              <Field name="expiryDate">
                                {({ field, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      {...getExpiryDateProps({
                                        placeholder: t("expiration_card_label"),
                                        onBlur: field.onBlur,
                                        onChange: field.onChange,
                                        onFocus: () =>
                                          props.setFieldValue(
                                            "focus",
                                            "expiry"
                                          ),
                                      })}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                              <Field name="cvc">
                                {({ field, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      {...getCVCProps({
                                        placeholder: "CVC",
                                        onBlur: field.onBlur,
                                        onChange: field.onChange,
                                        onFocus: () =>
                                          props.setFieldValue("focus", "cvc"),
                                      })}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                            </DoubleInputWrapper>
                          </div>
                        </section>
                        {address && (
                          <BillingAddres>
                            <BillingTitle>
                              {props.values.address_line1}
                            </BillingTitle>
                            <BillingText>
                              {`${props.values.address_city}, ${get(props, [
                                "values",
                                "address_state",
                              ])}`}
                            </BillingText>
                            <BillingText>
                              {`${props.values.address_country}, ${get(props, [
                                "values",
                                "address_zip",
                              ])}`}
                            </BillingText>
                          </BillingAddres>
                        )}
                        {!address && (
                          <>
                            <BillingSection>
                              <p>{t("add_billing_address")}</p>
                              <AddButton
                                width="32px"
                                height="32px"
                                onClick={() =>
                                  setVisibleAddAddress(!visibleAddAddress)
                                }
                              >
                                <span>+</span>
                              </AddButton>
                            </BillingSection>
                            <BillingContainer isVisible={visibleAddAddress}>
                              <Field name="address_line1">
                                {({ field, form, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      type="text"
                                      placeholder="Street address #1"
                                      {...field}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                              <Field name="address_line2">
                                {({ field, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      type="text"
                                      placeholder="Street address #2"
                                      {...field}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                              <DoubleInputWrapper>
                                <Field name="address_city">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="City"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                                <Field name="address_state">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="State / Province / Region"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                              </DoubleInputWrapper>
                              <DoubleInputWrapper>
                                <Field name="address_country">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="Country"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                                <Field name="address_zip">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="Zip Code"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                              </DoubleInputWrapper>
                            </BillingContainer>
                          </>
                        )}
                        <ButtonsContainer
                          isForm
                          mt="10px"
                          isDisabled={
                            !props.dirty || props.isSubmitting || !props.isValid
                          }
                        >
                          <button
                            type="button"
                            onClick={() => {
                              if (!address) {
                                setAddress(null)
                              }
                              setVisibleAddAddress(false)
                              setIsModalOpen(false)
                            }}
                          >
                            {t("cancel")}
                          </button>
                          <SubmitButton
                            small
                            isLoading={loading}
                            type="submit"
                            disabled={
                              !props.dirty ||
                              props.isSubmitting ||
                              !props.isValid
                            }
                          >
                            {t("create")}
                          </SubmitButton>
                        </ButtonsContainer>
                      </StyledForm>
                    )}
                  </Formik>
                </ModalContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
    </Container>
  )
}

OrgPayments.propTypes = {
  org: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    uid: PropTypes.string,
    stripeUserId: PropTypes.string,
  }).isRequired,
  orgId: PropTypes.string.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(OrgPayments)
