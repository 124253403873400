const organizationRoles = {
  es: [
    { value: "pharmaceutical_company", label: "Compañía Farmacéutica" },
    { value: "drugstore", label: "Farmacia" },
    { value: "medical_imaging", label: "Imágenes Médicas" },
    { value: "clinical_labs", label: "Laboratorios Clínicos" },
  ],
  en: [
    { value: "pharmaceutical_company", label: "Pharmaceutical Company" },
    { value: "drugstore", label: "Pharmaceutical" },
    { value: "medical_imaging", label: "Medical Imaging" },
    { value: "clinical_labs", label: "Clinical Laboratories" },
  ],
}

export default organizationRoles
