/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from "react"
import { DateRangePicker } from "react-dates"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"
import get from "lodash.get"
import { useToasts } from "react-toast-notifications"
import { DocumentReport } from "@styled-icons/heroicons-solid/DocumentReport"
import ActionButton from "../../../../components/atoms/ActionButton"
import { withFirebase } from "../../../../utils/Firebase"
import * as ROUTES from "../../../../utils/Constants/Routes"
import { AuthUserContext } from "../../../../context/Session"
import {
  paymentsGenerateUrl,
  paymentsGenerateLocalUrl,
  payoutsOrgsGenerateLocal,
  payoutsCreateUrl,
} from "../../../../utils/App"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
} from "../../../../components/atoms/Table"
import {
  Header,
  PaymentContainer,
  Title,
  List,
  FilterContainer,
} from "./styles"

const SummaryOthers = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const authUser = useContext(AuthUserContext)
  const [loadingTransactions, setLoadingTransactions] = useState(true)
  const [creating, setCreating] = useState(false)
  const [transactions, setTransactions] = useState([])
  const currentDay = moment().get("date") // 9 // moment().get("date")
  const [dates, setDates] = useState({
    startDate:
      currentDay <= 15
        ? moment().startOf("month")
        : moment().startOf("month").add(15, "days"),
    endDate:
      currentDay <= 15
        ? moment().startOf("month").add(14, "days")
        : moment().endOf("month"),
  })
  const [selectedUnixDates, setSelectedUnixDates] = useState({
    startDate: null,
    endDate: null,
  })
  const [focusedInput, setfocusedInput] = useState(null)

  const getSummary = useCallback(async () => {
    const token = await firebase.getIdToken()
    const summary = await fetch(
      authUser.roles.SUPER_ADMIN
        ? paymentsGenerateUrl
        : payoutsOrgsGenerateLocal,
      {
        method: "POST",
        body: JSON.stringify({
          ...(!authUser.roles.SUPER_ADMIN && {
            country: authUser?.country || "CR",
          }),
          withSummary: true,
          dates:
            selectedUnixDates.startDate && selectedUnixDates.endDate
              ? selectedUnixDates
              : null,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (summary.ok) {
      const data = await summary.json()
      setLoadingTransactions(false)
      setTransactions(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.roles.SUPER_ADMIN, selectedUnixDates])

  useEffect(() => {
    if (firebase && loadingTransactions) {
      getSummary()
    }
  }, [firebase, loadingTransactions, selectedUnixDates, getSummary])

  const onAddClick = async () => {
    try {
      setCreating(true)
      const report = await fetch(payoutsCreateUrl, {
        method: "POST",
        body: JSON.stringify({
          country: "CR",
          createdBy: authUser.email,
          ...transactions,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (report) {
        getSummary()
        addToast(t("saved_successfully"), {
          appearance: "success",
          placement: "bottom-center",
        })
      } else {
        addToast("No hay datos para guardar", {
          appearance: "info",
        })
      }
      setCreating(false)
    } catch (error) {
      setCreating(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  return (
    <PaymentContainer>
      <Header>
        <Title>{t("payments_summary_description_others")}</Title>
        <FilterContainer>
          <DateRangePicker
            small
            noBorder
            showDefaultInputIcon
            showClearDates
            isOutsideRange={() => false}
            startDateId="startDate"
            endDateId="endDate"
            startDate={dates.startDate}
            endDate={dates.endDate}
            onDatesChange={(selectedDates) => {
              setDates({
                startDate: selectedDates.startDate,
                endDate: selectedDates.endDate,
              })
            }}
            focusedInput={focusedInput}
            onFocusChange={(f) => {
              setfocusedInput(f)
            }}
            onClose={(dt) => {
              // console.log(dt)
              // console.log(dt.startDate.unix())
              // console.log(dt.endDate.unix())
              setSelectedUnixDates({
                startDate: dt.startDate.startOf("day").unix(),
                endDate: dt.endDate.endOf("day").unix(),
              })
              setLoadingTransactions(true)
            }}
          />
          <ActionButton
            disabled={
              transactions?.length <= 0 ||
              authUser.roles.SUPER_ADMIN ||
              creating
            }
            onActionClick={onAddClick}
            dark
          >
            <FilterContainer>
              <DocumentReport size={18} />
              <span>{t("create_report")}</span>
            </FilterContainer>
          </ActionButton>
        </FilterContainer>
      </Header>
      <List>
        <Table>
          <TableHeader>
            <TableHeaderItem>
              {authUser.roles.SUPER_ADMIN
                ? t("country_label")
                : t("display_name_label")}
            </TableHeaderItem>
            <TableHeaderItem>DNI</TableHeaderItem>
            <TableHeaderItem>Cuenta</TableHeaderItem>
            <TableHeaderItem>Tipo</TableHeaderItem>
            <TableHeaderItem>{t("session_type")}</TableHeaderItem>
            <TableHeaderItem>{t("session_pricing")}</TableHeaderItem>
            <TableHeaderItem>{t("fees_label")}</TableHeaderItem>
            <TableHeaderItem>{t("total_label")}</TableHeaderItem>
            <TableHeaderItem>{t("status_label")}</TableHeaderItem>
          </TableHeader>
          {!loadingTransactions && transactions.length > 0 ? (
            transactions.map((ts) => {
              return (
                <TableRow
                  key={`sum-${
                    authUser.roles.SUPER_ADMIN
                      ? ts.drugstore.country
                      : ts.drugstore.businessName
                  }`}
                >
                  <TableRowItem>
                    <Link
                      to={{
                        state: {
                          transactions: get(ts, "orders", []),
                        },
                        pathname: ROUTES.PAYMENT_DETAIL_OTHERS.replace(
                          ":id",
                          authUser.roles.SUPER_ADMIN
                            ? ts.drugstore.country
                            : ts.drugstore.businessName
                        ),
                      }}
                    >
                      {authUser.roles.SUPER_ADMIN
                        ? ts.drugstore.country
                        : ts.drugstore.businessName}
                    </Link>
                  </TableRowItem>
                  <TableRowItem>
                    {ts.drugstore.licenceNumber || "N/A"}
                  </TableRowItem>
                  <TableRowItem>
                    {ts.drugstore?.banks?.[0]?.bankFullName || "N/A"}
                  </TableRowItem>
                  <TableRowItem>
                    {ts.drugstore?.banks?.[0]?.accountTypeName || "N/A"}
                  </TableRowItem>
                  <TableRowItem>Farmacia</TableRowItem>
                  <TableRowItem>{`${parseFloat(ts.summary.localTotal).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(
                    ts.summary.localTotalOrgFees
                  ).toFixed(2)}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(
                    ts.summary.localTotal - ts.summary.localTotalOrgFees
                  ).toFixed(2)}`}</TableRowItem>
                  <TableRowItem>Pendiente</TableRowItem>
                </TableRow>
              )
            })
          ) : (
            <EmptyOrLoadingContainer>
              {loadingTransactions ? t("loading") : "No results"}
            </EmptyOrLoadingContainer>
          )}
        </Table>
      </List>
    </PaymentContainer>
  )
}

SummaryOthers.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(SummaryOthers)
