import styled, { css } from "styled-components"
import ButtonWithLoader from "../../../../components/atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  margin: 20px;
  height: calc(100vh - 140px);
  overflow-y: scroll;
`

export const FormContainer = styled.div`
  height: calc(100vh - 140px);
  overflow-y: scroll;
`

export const SubmitButton = styled(ButtonWithLoader)`
  position: absolute;
  top: 60px;
  right: 40px;
  width: 250px;
  margin: 20px 0;
`

export const Title = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    text-align: left;
    color: ${theme.color.black.normal};
    margin-top: 22px;
  `
)

export const SectionTitle = styled.p(
  ({ theme }) => css`
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 150%;
    color: ${theme.color.black.normal};
  `
)

export const ChargesTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 56px;
`

export const Subtitles = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

export const Line = styled.div`
  margin: 10px 0px;
  width: 100%;
  height: 2px;
  background: #cbd5e0;
  margin-left: 12px;
`

export const CountryLine = styled.div`
  margin: 40px 40px;
  width: 40%;
  height: 2px;
  background: #4d66f5;
`

export const InputContainer = styled.div`
  width: 300px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const SearchContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`

export const ProviderChargesStyledForm = styled.form`
  margin: 20px;
  overflow-y: scroll;
  width: 60%;
`

export const ChargesLine = styled.div`
  margin: 40px 40px;
  width: 95%;
  height: 2px;
  background: #4d66f5;
`

export const PutChargesToAll = styled.div`
  margin-top: 30px;
  align-self: center;
  cursor: pointer;
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props?.alignItems || "flex-start"};
  justify-content: space-around;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.color.white.normal};
  width: 500px;
  height: 260px;
  padding: 22px;
`

export const RegularFont = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

export const ActionButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: ${(props) => props.theme.color.secondary};
    cursor: pointer;
  }
`

export const ApplyToAllButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: red;
    cursor: pointer;
  }
`
