import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import get from "lodash.get"
import { ChromeReaderMode } from "@styled-icons/material/ChromeReaderMode"
import { PersonOutline } from "@styled-icons/material/PersonOutline"
import { Block } from "@styled-icons/material/Block"
import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt"
import { UserCheck } from "@styled-icons/fa-solid/UserCheck"
import { useTranslation } from "react-i18next"
import CustomModal from "../../atoms/CustomModal"
import ImageWithText from "../ImageWithText"
import { withFirebase } from "../../../utils/Firebase"
import Spinner from "../../atoms/Spinner"

const OrgCard = ({
  org,
  toActivate,
  firebase,
  isRemovingOrg,
  deleteOrganization,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [orgForm, setOrgForm] = useState([])
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [isOpenBlockModal, setOpenBlockModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () =>
    history.push(`/organization/${org.uid}`, {
      currentOrg: org,
    })

  useEffect(() => {
    if (firebase && org.type) {
      const getForm = async () => {
        const form = await firebase.getOrgForm(org.uid, "agents")
        setOrgForm(form.docs)
      }
      getForm()
    }
  }, [firebase, org])

  useEffect(() => {
    if (isRemovingOrg && removeModalOpen) {
      setRemoveModalOpen(false)
    }
  }, [isRemovingOrg, removeModalOpen])

  const openRemoveModal = (e) => {
    e.stopPropagation()
    setRemoveModalOpen(true)
  }

  const openBlockModal = async (e) => {
    e.stopPropagation()
    setOpenBlockModal(true)
  }

  const blockAgent = async () => {
    await firebase.updateOrgData({
      orgId: org.uid,
      orgData: {
        ...org,
        status: org.status === "active" ? "inactive" : "active",
        active: !org.active,
        indexDocument: true,
      },
    })
    setIsLoading(false)
    setOpenBlockModal(false)
    window.location.reload()
  }

  const logoURL = get(org, "bannerUrl", get(org, "logoURL"))

  return (
    <>
      <CardContainer
        onClick={handleClick}
        toActivate={toActivate || org.status !== "active"}
      >
        <ImagesContainer>
          <ImageWithText src={logoURL} emptyMessage="OrgLogo" />
        </ImagesContainer>
        <BlockContainer
          title={t("block_org")}
          onClick={(e) => openBlockModal(e)}
        >
          {org?.status === "active" ? (
            <Block size="24" />
          ) : (
            <UserCheck size="24px" />
          )}
        </BlockContainer>
        <TrashContainer onClick={(e) => openRemoveModal(e)}>
          <TrashAlt size="24" />
        </TrashContainer>
        {removeModalOpen && (
          <CustomModal
            key="deleteOrgConfirmationModal"
            modalOpen={removeModalOpen}
            setModalOpen={setRemoveModalOpen}
            showClose={false}
          >
            <ModalContainer>
              <Title>{t("delete_modal_title")}</Title>
              <RegularFont>{t("delete_modal_paragraph")}</RegularFont>
              <RowContainer>
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation()
                    setRemoveModalOpen(false)
                  }}
                >
                  <p>{t("exit")}</p>
                </ActionButton>
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteOrganization(org)
                  }}
                  disabled={isRemovingOrg}
                >
                  <p>{t("delete_button")}</p>
                </ActionButton>
              </RowContainer>
            </ModalContainer>
          </CustomModal>
        )}
        <Info>
          <Label topPosition="0">
            <span>{t("information")}</span>
          </Label>
          <PersonalData>
            <p>{get(org, "businessName", "Name")}</p>
            <p>{get(org, "orgEmail", get(org, "email", "Email"))}</p>
            <span>
              <ChromeReaderMode size="16" /> {org.legalId}
            </span>
          </PersonalData>
        </Info>
        <Badge>
          <span>
            <PersonOutline size="18" />
            {orgForm.length}
          </span>
        </Badge>
      </CardContainer>
      {isOpenBlockModal && (
        <CustomModal
          key="blockAgentModal"
          modalOpen={isOpenBlockModal}
          setModalOpen={setOpenBlockModal}
          showClose={false}
        >
          <ModalContainer>
            <Title>{t("delete_modal_title")}</Title>
            <RegularFont>
              {org?.status === "active"
                ? t("block_org_text")
                : t("unblock_org_text")}
            </RegularFont>
            <RowContainer>
              {isLoading && <Spinner isLoading />}
              {!isLoading && (
                <>
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setOpenBlockModal(false)
                    }}
                  >
                    <p>{t("exit")}</p>
                  </ActionButton>
                  <BlockAgentButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsLoading(true)
                      blockAgent()
                    }}
                  >
                    <p>
                      {org?.status === "active"
                        ? t("block_org")
                        : t("unblock_org")}
                    </p>
                  </BlockAgentButton>
                </>
              )}
            </RowContainer>
          </ModalContainer>
        </CustomModal>
      )}
    </>
  )
}

OrgCard.defaultProps = {
  toActivate: false,
  isRemovingOrg: false,
  deleteOrganization: () => {},
}

OrgCard.propTypes = {
  org: PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.string,
    uid: PropTypes.string,
    phoneNumber: PropTypes.string,
    type: PropTypes.string,
    legalId: PropTypes.string,
    logoURL: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  toActivate: PropTypes.bool,
  firebase: PropTypes.shape().isRequired,
  isRemovingOrg: PropTypes.bool,
  deleteOrganization: PropTypes.func,
}

const CardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: ${(props) =>
    props.toActivate ? "rgba(77, 102, 245, 0.1)" : "white"};
`

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  position: absolute;
  width: 90px;
  height: 32px;
  background: #f2f2f2;
  border-radius: 100px;
  left: 0;
  bottom: 36px;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      margin-right: 6px;
    }
  }
`

const Info = styled.div`
  position: relative;
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`

const Label = styled.div`
  position: absolute;
  top: ${(props) => props.topPosition};
  left: 0;
  & > span {
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 1.5px;
    text-align: left;
    color: ${(props) => props.theme.color.black["300"]};
    text-transform: uppercase;
  }
`

const PersonalData = styled.div`
  position: absolute;
  top: 18px;
  left: 0;
  & > p {
    text-align: left;
    margin: 8px 0;
    &:first-of-type {
      font-size: 1.25rem;
    }
  }

  & > span {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > svg {
      margin-right: 8px;
    }
  }
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.color.white.normal};
  width: 500px;
  height: 260px;
  padding: 22px;
`

const TrashContainer = styled.div`
  position: absolute;
  height: 56px;
  width: 56px;
  cursor: pointer;
  right: 0;
  top: 20px;
  &:hover {
    transform: scale(1, 1.1);
  }
`

export const RegularFont = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

export const Title = styled.h5`
  margin: 0;
  ${(props) => props.theme.font_size.large};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
  & > p {
    font-weight: bold;
  }
  & > p:hover {
    color: ${(props) => props.theme.color.lightPurple};
    cursor: pointer;
  }
`

const ActionButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: ${(props) => props.theme.color.secondary};
    cursor: pointer;
  }
`

const BlockContainer = styled.div`
  position: absolute;
  height: 56px;
  width: 40px;
  cursor: pointer;
  right: 60px;
  top: 20px;
  text-align: center;
  &:hover {
    transform: scale(1, 1.1);
  }
`

const BlockAgentButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: red;
    cursor: pointer;
  }
`

export default withFirebase(OrgCard)
