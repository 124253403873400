import app from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/storage"
import moment from "moment"
import isNill from "lodash.isnil"
import config from "./config"
import { countries } from "../App"

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.storage = app.storage()
  }

  async getIdToken() {
    return this.auth.currentUser.getIdToken()
  }

  // *** Auth API ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  async doPasswordReset(email) {
    return this.auth.sendPasswordResetEmail(email)
  }

  async reset({ email }) {
    return this.auth.sendPasswordResetEmail(email.toLowerCase())
  }

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.email)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data()
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {}
            }

            // merge auth and db user
            const newAuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            }
            next(newAuthUser)
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = (email) => this.firestore.collection("users").doc(email)

  getUsersByType = async (type, country) => {
    let users = null
    const validCountries = countries
      .filter((c) => c.valid)
      .map((co) => co.value)
    if (country) {
      users = await this.firestore
        .collection("users")
        .where("userType", "==", type)
        .where("countryBusiness", "==", country)
        .get()
      return users
    }
    if (validCountries.length >= 10) {
      const localUsers = { docs: [] }
      const userPromises = validCountries.map((c) => {
        return this.firestore
          .collection("users")
          .where("userType", "==", type)
          .where("country", "==", c)
          .get()
      })
      const data = await Promise.all(userPromises)
      data.forEach(async (d) => {
        if (d.docs.length > 0) {
          const { docs } = d
          localUsers.docs.push(...docs)
        }
      })
      return localUsers
    }

    users = await this.firestore
      .collection("users")
      .where("userType", "==", type)
      .where("country", "!=", null)
      .where("country", "in", validCountries)
      .get()
    return users
  }

  getNoCountryUsers = async (type) => {
    const validCountries = countries
      .filter((c) => c.valid)
      .map((co) => co.value)
    try {
      // const usersT = { docs: [] }
      const usersT = await this.firestore
        .collection("users")
        .where("userType", "==", type)
        .where("country", "not-in", [...validCountries, null])
        .get()

      const nullCountryUsers = await this.firestore
        .collection("users")
        .where("userType", "==", type)
        .where("country", "==", null)
        .get()

      let result = null
      if (
        nullCountryUsers &&
        usersT &&
        nullCountryUsers.docs.length > 0 &&
        usersT.docs.length > 0
      ) {
        result = [...usersT.docs, ...nullCountryUsers.docs]
      } else if (nullCountryUsers && nullCountryUsers.docs.length > 0) {
        result = nullCountryUsers.docs
      } else if (usersT && usersT.docs.length > 0) {
        result = usersT.docs
      }
      return result && result.length > 0
        ? result.map((d) => {
            return { id: d.id, ...d.data(), country: "Others" }
          })
        : true
    } catch (error) {
      return []
    }
  }

  getUserForm = async (email, form) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc(form)
      .get()

  users = () => this.firestore.collection("users")

  chats = () => this.firestore.ref("chats")

  saveUserData = async ({ email, data }) => {
    const newUserRef = this.firestore.doc(`users/${email}`)
    return newUserRef.set(
      {
        ...data,
        indexDocument: true,
      },
      { merge: true }
    )
  }

  saveAgentData = async ({ orgId, id, data }) => {
    const newAgentRef = this.firestore.doc(
      `organizations/${orgId}/agents/${id}`
    )
    return newAgentRef.set(data, { merge: true })
  }

  saveNewOrg = async ({ data }) => {
    const newOrgRef = this.firestore.collection("organizations").doc()
    await newOrgRef.set({
      ...data,
    })
    return newOrgRef.id
  }

  org = (id) => this.firestore.collection("organizations").doc(id)

  getOrgsByType = async (type, id, country) => {
    if (id) {
      return this.firestore.collection("organizations").doc(id).get()
    }
    if (country) {
      return this.firestore
        .collection("organizations")
        .where("type", "==", type)
        .where("country", "==", country)
        .get()
    }
    return this.firestore
      .collection("organizations")
      .where("type", "==", type)
      .get()
  }

  getOrgs = async (id, country) => {
    if (id) {
      return this.firestore.collection("organizations").doc(id).get()
    }
    if (country) {
      return this.firestore
        .collection("organizations")
        .where("country", "==", country)
        .get()
    }
    return this.firestore.collection("organizations").get()
  }

  getOrgForm = async (id, type) =>
    this.firestore.collection("organizations").doc(id).collection(type).get()

  addOrgProduct = async ({ orgId, productData }) => {
    const newProductRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
    return newProductRef.add({
      ...productData,
    })
  }

  addOrgProducts = async ({ orgId, products }) => {
    const getDuplicatedProduct = (newProduct, indexedProduct) =>
      indexedProduct.sku === newProduct.sku &&
      indexedProduct.cie11 === newProduct.cie11 &&
      indexedProduct.pharmaceuticalPresentation ===
        newProduct.pharmaceuticalPresentation

    const orgProducts = await this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .get()

    const productsToUpdate = []
    const productsToCreate = []

    const parsedDbProducts = orgProducts.docs.map((doc) => ({
      id: doc.id,
      ref: doc.ref,
      ...doc.data(),
    }))

    products.forEach((localProduct) => {
      const isDuplicatedProduct = parsedDbProducts.find((prod) =>
        getDuplicatedProduct(localProduct, prod)
      )

      if (!isNill(isDuplicatedProduct)) {
        productsToUpdate.push({
          ref: isDuplicatedProduct.ref,
          data: localProduct,
        })
      } else {
        productsToCreate.push(localProduct)
      }
    })

    const newProductOperations = productsToCreate.map((prod) =>
      this.addOrgProduct({ orgId, productData: prod })
    )

    const updateProductOperations = productsToUpdate.map((prod) =>
      prod.ref.update(prod.data)
    )

    return Promise.all([newProductOperations, updateProductOperations])
  }

  updateOrgProduct = async ({ orgId, documentId, productData }) => {
    const existingProduct = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .doc(documentId)
    return existingProduct.update(productData)
  }

  deleteOrgProduct = async ({ orgId, documentId }) => {
    const existingProduct = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .doc(documentId)
    return existingProduct.delete()
  }

  updateOrgAgent = async ({ orgId, id, agentData }) => {
    const existingAgent = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")
      .doc(id)
    return existingAgent.update(agentData)
  }

  updateOrgData = async ({ orgId, orgData }) => {
    const existingOrg = this.firestore.collection("organizations").doc(orgId)
    return existingOrg.set(
      {
        ...orgData,
      },
      {
        merge: true,
      }
    )
  }

  getOrgAgent = async (orgId, agent) =>
    this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")
      .doc(agent)
      .get()

  addOrgColaborator = async ({ orgId, agentData }) => {
    const newUserRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")

    // const newUserRef = this.firestore.doc(
    //   `organizations/${orgId}/agents/${email}`
    // )
    // return newAddressRef.add({
    return newUserRef.add(agentData)
  }

  uploadToStorage(name, blob, metadata) {
    return this.storage
      .ref()
      .child(name)
      .put(blob, { customMetadata: metadata })
  }

  async getDashboard(email) {
    return this.firestore
      .collection("transactions")
      .where("metadata.providerEmail", "==", email)
      .get()
  }

  async getMonthDashboard() {
    return this.firestore
      .collection("transactions")
      .where("createdAt", ">=", moment().startOf("month").valueOf())
      .where("createdAt", "<=", moment().endOf("month").valueOf())
      .get()
  }

  async getFilteredDashboardData(dates) {
    return this.firestore
      .collection("transactions")
      .where("createdAt", ">=", dates.start)
      .where("createdAt", "<=", dates.end)
      .get()
  }

  async getFormOptions(type) {
    return this.firestore.collection("formOptions").doc(type).get()
  }

  async saveExchanges(data, collectionName) {
    const exchangesRef = this.firestore
      .collection("formOptions")
      .doc("exchanges")

    return exchangesRef.set(
      {
        [collectionName]: data,
      },
      { merge: true }
    )
  }

  subscribeToTokens({ orgId, onSnapshot }) {
    const tokensRef = this.firestore.collection("organizations").doc(orgId)
    return tokensRef
      .collection("tokens")
      .orderBy("createdAt", "asc")
      .onSnapshot(onSnapshot)
  }

  addOrgBillingAddress = async ({ orgId, billingAddress }) => {
    const newAddressRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("addresses")
    return newAddressRef.add({
      ...billingAddress,
    })
  }

  getUserByReferenceCode = (referenceCode) => {
    return this.firestore
      .collection("users")
      .where("referenceCode", "==", referenceCode)
      .get()
  }

  getOrganizationByReferenceCode = (referenceCode) => {
    return this.firestore
      .collection("organizations")
      .where("referenceCode", "==", referenceCode)
      .get()
  }

  getPricing = () => {
    return this.firestore
      .collection("environment")
      .doc("settings/doc/pricing")
      .get()
  }

  updatePricing = (data) => {
    const environmentRef = this.firestore
      .collection("environment")
      .doc("settings/doc/pricing")

    return environmentRef.set(data, { merge: true })
  }

  updateProviderCharges = (data) => {
    const environmentRef = this.firestore
      .collection("environment")
      .doc("settings/doc/pricing")

    return environmentRef.set(data, { merge: true })
  }

  async saveProviderChargesByDoc({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("chargesByDoc")
      .set(data, {
        merge: true,
      })
  }

  version = () => {
    return "1.0.0"
  }
}

export default Firebase
