/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import { withFirebase } from "../../../utils/Firebase"
import { generatePassword, getReferenceCode } from "../../../utils/App"
import CustomInput from "../../atoms/CustomInput"
import CustomSelect from "../../atoms/CustomSelect"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"

const organizationRoles = [
  { value: "business_partner", label: "Administrador" },
  { value: "medical_visitor", label: "Visitador Médico" },
]

const VisitorDrawer = ({
  firebase,
  open,
  closeDrawer,
  orgId,
  orgName,
  country,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)
  const createAdminUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_visitor")}
      internal
    >
      <div>
        <Formik
          initialValues={{
            type: "business_partner", // "medical_visitor
            email: undefined,
            password: generatePassword(),
            displayName: undefined,
            phoneNumber: undefined,
          }}
          onSubmit={async (values, { resetForm }) => {
            setIsCreating(true)
            try {
              const token = await firebase.getIdToken()
              await fetch(createAdminUrl, {
                method: "POST",
                body: JSON.stringify({ user: values }),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }).then(() => {
                try {
                  const templateId =
                    values.type === "business_partner" ? 2358080 : 2358085 // admin / invite visitor
                  // This timeout gives time for the firebase trigger to be executed
                  setTimeout(async () => {
                    await fetch(mailerUrl, {
                      method: "POST",
                      body: JSON.stringify({
                        data: {
                          templateId,
                          email: values.email,
                          variables: {
                            subject: `Te han invitado como ${
                              values.type === "business_partner"
                                ? "Administrador"
                                : "Visitador Médico"
                            } a la organización ${orgName}`,
                            orgName,
                            displayName: values.displayName,
                            password: values.password,
                          },
                        },
                      }),
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                    })
                    const getCodeToReference = getReferenceCode()
                    await firebase.addOrgColaborator({
                      orgId,
                      email: values.email,
                      agentData: {
                        email: values.email,
                        displayName: values.displayName,
                        phoneNumber: values.phoneNumber,
                        phone: values.phoneNumber,
                        active: true,
                        referenceCode: getCodeToReference,
                        ...(values.type === "business_partner"
                          ? { officeId: orgId, role: "Admin" }
                          : { role: "visitor" }),
                      },
                    })

                    await firebase.saveUserData({
                      email: values.email,
                      data: {
                        displayName: values.displayName,
                        email: values.email,
                        active: true,
                        status: "active",
                        country,
                        userType: values.type,
                        phoneNumber: values.phoneNumber,
                        organizationId: orgId,
                        referenceCode: getCodeToReference,
                      },
                    })

                    addToast(t("saved_successfully"), {
                      appearance: "success",
                      placement: "bottom-center",
                    })
                    resetForm({ value: "" })
                    setIsCreating(false)
                    closeDrawer()
                  }, 2500)
                } catch (e) {
                  addToast(t("unexpected_error"), {
                    appearance: "error",
                    placement: "bottom-center",
                  })
                }
              })
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            type: Yup.string().required(t("error_all_fields_required")),
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            displayName: Yup.string().required(t("error_all_fields_required")),
            phoneNumber: Yup.string().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <p>
                Ingrese los datos de contacto del nuevo colaborador que desea
                crear dentro de la organización.
              </p>
              <CustomSelect
                value={props.values.type}
                formKey="type"
                lngKey="usr_type"
                handleChange={props.setFieldValue}
                items={organizationRoles}
                showError={props.errors.type}
              />
              <CustomInput
                touched={props.touched.displayName}
                errors={props.errors.displayName}
                icon="user"
                value={props.values.displayName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("display_name_label")}
                type="text"
                id="displayName"
                name="displayName"
              />
              <CustomInput
                touched={props.touched.email}
                errors={props.errors.email}
                icon="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("email_address")}
                type="email"
                id="email"
                name="email"
              />
              <CustomInput
                touched={props.touched.phoneNumber}
                errors={props.errors.phoneNumber}
                icon="phone"
                value={props.values.phoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("phone_label")}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
              />
              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

VisitorDrawer.defaultProps = {
  open: false,
}

VisitorDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}
export default withFirebase(VisitorDrawer)
