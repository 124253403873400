import React from "react"
import { ThemeProvider } from "styled-components"
import { compose } from "recompose"
import { ToastProvider } from "react-toast-notifications"
// import { DateRangePicker } from "react-dates"
import Routes from "./components/molecules/Routes"
import { withTrans } from "./i18n/withTrans"
import { withAuthentication } from "./context/Session"
import "./static/fonts/fonts.css"
import theme from "./theme"
import GlobalStyle from "./theme/globalStyle"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <div className="App">
        <ToastProvider autoDismiss>
          <Routes />
          {/* <DateRangePicker startDateId="startDate" endDateId="endDate" /> */}
        </ToastProvider>
      </div>
    </>
  </ThemeProvider>
)

const RootApp = compose(withTrans, withAuthentication)(App)

export default RootApp
